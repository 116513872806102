import styled from 'styled-components';
const BoldFont = 'Rubik-Medium';

const DropdownTitle = styled.div`
  .compare-title{
    font-family: 'Rubik-Medium';
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #24395A;  
    margin-top: 75px;
    margin-bottom: 34px;  
  }
  .flex-title{
    display: flex;
  .subcompare-title{
    .separate-name{
        display: flex;
        .circle-img{
            background: rgba(156, 156, 156, 0.2);
            border-radius: 21px;
        }
        .condition{           
            font-family: 'Rubik';
            font-size: 18px;
            line-height: 24px;
            text-transform: capitalize;
            color: #69C2FF;
            margin-left: 9px;
            margin-top: 3px;
            margin-right: 64px;
            
        }
    }
  }
}
`;

const TeamTabContainerV2 = styled.div`
  width: ${(props) => props.setTabWidth ? '100%' : '100%'};
  // background-color: #fff;
  display: flex;
  margin-top: 62px;
  margin-bottom: 18px;
  @media (min-width: 820px){
     justify-content: center;
  }
    > div {
        float: left;
        padding: 10px 0px;
        font-family: ${BoldFont};
        font-size: 18px;
        color: #fff;
        cursor: pointer;
    }
    .show-arrow{
      display: flex;
      margin-right: 218px;
      // @media (max-width: 1280px) {
      //   width: 100%
      //   align-items: center;
        
      // }
      @media (max-width: 600px){
        .arow-img{
          transform: rotate(35.36deg);
          // width: 35.35px;
          // height: 26.49px;
          width: 46.35px;
          height: 40.49px;
        }
      }
      .arrow-text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;               
        display: flex;
        align-items: center;       
        color: #00B1BC;
        margin-top: 25px; 
        @media (max-width: 600px){
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-top: 19px;
          width: 30%
        }  
        @media (max-width: 360px){
          width: 31%
        }    
      }
      .arrow-emoji{
        margin-top: 25px;
        margin-left: 3px;
        @media (max-width: 600px){
          margin-top: 35px;
          margin-left:-80px
        }  
        @media (max-width: 400px){
          margin-left:-70px
        }
      }
    }
    div:first-child {
    width: 100%;
    display: flex;
    // margin: auto;
    margin-left: 400px;
    @media (max-width: 500px){
      width: 100%;
      margin-left: 0px;
      padding-bottom: 0px;
    }
    @media (min-width: 820px){
      width: 100%;
      // margin-left: 0px;
      // margin-top: 157%;
    }
    // @media (max-width: 1280px){
    //   width: 100%;
    //   margin-left: 400px;
    // }
    div:first-child {
      width: 410px;
      border-radious: 20px;
      height: 48px;
      background: #24395a;
      background-color: rgba(36, 57, 90, 0.04);
      border-radius: 30px;
      margin: 0px;
      @media (max-width: 500px){
        width: 100%;
        margin-left: 0px;
      }
      div:first-child {
        background-color: ${(props) => props.active ? '#24395A' : 'transparent'};
        color: ${(props) => props.active ? '#fff' : '#24395A'};
        height: 44px;
        width: 202px;
        margin: 0px;
        padding-top: 11px;
        border-radius: 30px;
        font-family: Rubik;
        font-family: ${(props) => props.active ? 'Rubik-Medium' : 'Rubik'};
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-transform: capitalize;
        padding-left: 35px;
        // box-shadow: ${(props) => !props.active ? '11px 14px 14px rgba(0, 0, 0, 0.12)' : ''};
        @media (max-width: 450px){
          padding-left: 24px;
        }
    }
    >div:nth-child(2){
        background-color: ${(props) => !props.active ? '#24395A' : 'transparent'};
        color: ${(props) => !props.active ? '#fff' : '#24395A'};
        height: 44px;
        width: 203px;
        margin: 0px;
        padding-top: 11px;
        border-radius: 30px;
        justify-content: flex-end;
        font-family: Rubik;
        font-family: ${(props) => props.active ? 'Rubik' : 'Rubik-Medium'};
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        text-transform: capitalize;
        padding-right: 0px;
        box-shadow: ${(props) => !props.active ? '11px 14px 14px rgba(0, 0, 0, 0.12)' : ''};
    }
  }
  }
    @media (max-width: 500px){
        > div {
            width: 100%;
            margin-left: 55%
            padding-top: 0px;
            
        }
    }
  // @media (max-width: 1280px) {
  //   width: 100%
  //   display: flex;
  //   flex-direction: column;

  // }
  @media (max-width: 600px) {
    padding: 0% 0%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
`;

const AddOnContainerV2 = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0px 0px;
  background-color: #F5FBFF
   > div {
     margin-bottom: 40px;
    > h1 {
        font-size: 40px;
        color: #404555;
        font-family: Rubik-Medium;
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 60px;
        font-weight: 700;
      }
    > p {
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
  
      
      text-align: center;
      
      color: #69C2FF;
     }
   }
   .web-cards{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1140px;
    
   //  margin-right: auto;
    margin-bottom: auto;
   //  margin-left: auto;
   @media (max-width: 500px){
     display: none;
   }
  }
   .cards {
     display: flex;
     flex-direction: row;
     width: 100%;
     max-width: 1140px;
     
    //  margin-right: auto;
     margin-bottom: auto;
    //  margin-left: auto;
    @media (max-width: 2000px){
      display: none;
    } 
   }
   @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
    > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;
    }
   }
`;

const AddOnCardV2 = styled.div`
  float: left;
  margin: 0px 10px;
  
  width: 400px;
  border-radius: 4px;
  background-color: #fff;
  max-width: 500px;
  padding: 25px 0px 0px;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border: 2px solid #24395A;
  box-sizing: border-box;
  border-radius: 6px;
  &:hover {
    background: linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%);
    box-shadow: 0px 35px 31px rgba(0, 0, 0, 0.12);
    border:none;

    > div {
      > h1 {
        color: white;
      }
      > p{
        color: white;
      }
      .descmain{
        background: white;
        .desc-letter{
          color: #24395A;
        }
      }   
      >div{  
        > h3{
          color: white;
        }
        >h6{
          >sub{
            color:white;
          }
        }
      }
      > span {
        color: #24395A;
      }
    }
    .button {
      > div{
        color: #24395A;
        background-color: white;
      }
    }
    .point-wrap{
      .tick-img{
        // background: url("/images/tickimagewhite.svg") ;
        display: none;
      }
      .tick-img-white{
        width:20px;
        height:20px;
        display: flex;
        position: absolute;
        z-index: 99;
      }
      .subpoint1{
        color: white;
      }
    }
  }
  > div {
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    > img {
      width: auto;
      height: 88px;
    }
    > h1 {
      font-family: ${BoldFont};
      font-size: 24px;
      font-weight: 500;
      line-height: 80%;
      color: #404555;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 80%;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      margin-bottom:25px;
      margin-top: 0px;
    }
    > p {
      font-family: Rubik;
      font-size: 14px;
      line-height: 25px;
      color: #3f4557;
      letter-spacing: 0;
      font-weight: 400;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #9C9C9C;
      margin-bottom: 0px;
      // margin-top: 10px;
      text-transform: none;
    }
    .descmain{
      width: 170px;
      height: 20px;    
      background: linear-gradient(90deg, rgb(0, 177, 188, 0.3) -1.11%, rgb(117, 217, 163, 0.3) 97.46%);
      // opacity: 0.3;
      border-radius: 3px;
      // margin-left: 93px;
      margin-top: 10px;
      display: inline-block;
      margin-bottom: 23px;
    .desc-letter{
      margin-top:0px;
      width: 150px;
      height: 16px;     
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;     
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: lowercase;     
      color: #24395A; 
      margin-left:10px;
      padding-top: 2px;    
    }
  }
  .rupee-section{
    display: flex;
    // margin-left: 85px;
    justify-content: center;
    >h3{
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    >h6{
      margin-top: auto;
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 35px;
      // line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 28px;
      margin-bottom: 10px;
    }
  }
    >span{
      font-family: Rubik-Medium;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #F6797D;
      display: inline-block;
    }
  }
  .button {
    width: 100%;
    display: flex;
    align-items: center;
    
    > div {
      width: 220px;
      height: 50px;
      padding-top: 17px;
      background-color: #3A74A7;
      color: #fff;  
      font-size: 18px;
      cursor: pointer;
      font-family:'Rubik-Medium';
      background: #24395A;
      border-radius: 3px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
  .to-seemore{
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    >button{
      border: none;
      background: none;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;   
      text-transform: lowercase; 
      color: rgba(36, 57, 90, 0.6);
    }
  }
  .hr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  >div{
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      display: none;
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      // margin-left: 10px;
      margin-left: 30px;
      margin-bottom: 0px;
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 20px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    min-height: 417px; 
    margin-bottom: 20px;
  }



// for mobile view ---------------------------------------------------------------------------------

  .hrr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 20px;
    margin-bottom: 25px;
    // @media (max-width: 500px){
    //   display: none;
    // }
  }

  .display-points{
    // @media (max-width: 500px){
    //   display:  none;
    // }
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      display: none;
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      margin-left: 30px;
      margin-bottom: 0px;
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 0px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    min-height: 360px; 
    margin-bottom: 20px
  }
`;

const AddOnCardV2Mobile = styled.div`
  float: left;
  margin: 0px 10px;
  
  width: 400px;
  border-radius: 4px;
  background-color: #fff;
  max-width: 500px;
  padding: 25px 0px 0px;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border: 2px solid #24395A;
  box-sizing: border-box;
  border-radius: 6px;
  // &:hover {
  //   background: linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%);
  //   box-shadow: 0px 35px 31px rgba(0, 0, 0, 0.12);
  //   border:none;

  //   > div {
  //     > h1 {
  //       color: white;
  //     }
  //     > p{
  //       color: white;
  //     }
  //     .descmain{
  //       background: white;
  //       .desc-letter{
  //         color: #24395A;
  //       }
  //     }   
  //     >div{  
  //       > h3{
  //         color: white;
  //       }
  //       >h6{
  //         >sub{
  //           color:white;
  //         }
  //       }
  //     }
  //     > span {
  //       color: #24395A;
  //     }
  //   }
  //   .button {
  //     > div{
  //       color: #24395A;
  //       background-color: white;
  //     }
  //   }
  //   .point-wrap{
  //     .tick-img{
  //       // background: url("/images/tickimagewhite.svg") ;
  //       display: none;
  //     }
  //     .tick-img-white{
  //       width:20px;
  //       height:20px;
  //       display: flex;
  //       position: absolute;
  //       z-index: 99;
  //     }
  //     .subpoint1{
  //       color: white;
  //     }
  //   }
  // }
  > div {
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    > img {
      width: auto;
      height: 88px;
    }
    > h1 {
      font-family: ${BoldFont};
      font-size: 24px;
      font-weight: 500;
      line-height: 80%;
      color: #404555;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 80%;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      margin-bottom:25px;
      margin-top: 0px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
    > p {
      font-family: Rubik;
      font-size: 14px;
      line-height: 25px;
      color: #3f4557;
      letter-spacing: 0;
      font-weight: 400;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #9C9C9C;
      margin-bottom: 0px;
      // margin-top: 10px;
      text-transform: none;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#9C9C9C'};
      }
    }
    .descmain{
      width: 170px;
      height: 20px;    
      background: linear-gradient(90deg, rgb(0, 177, 188, 0.3) -1.11%, rgb(117, 217, 163, 0.3) 97.46%);
      // opacity: 0.3;
      border-radius: 3px;
      // margin-left: 93px;
      margin-top: 10px;
      display: inline-block;
      margin-bottom: 23px;
      @media (max-width: 1000px){
        background: ${({active}) => active ? '#fff' : 'linear-gradient(90deg, rgb(0, 177, 188, 0.3) -1.11%, rgb(117, 217, 163, 0.3) 97.46%);'};
      }
    .desc-letter{
      margin-top:0px;
      width: 150px;
      height: 16px;     
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;     
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: lowercase;     
      color: #24395A; 
      margin-left:10px;
      padding-top: 2px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#24395A' : '#24395A'};
      }
    }
  }
  .rupee-section{
    display: flex;
    // margin-left: 85px;
    justify-content: center;
    >h3{
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 25px;
      margin-bottom: 10px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
    >h6{
      margin-top: auto;
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 35px;
      // line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 28px;
      margin-bottom: 10px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
  }
    >span{
      font-family: Rubik-Medium;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #F6797D;
      display: inline-block;
    }
  }
  .button {
    width: 100%;
    display: flex;
    align-items: center;
    
    > div {
      width: 220px;
      height: 50px;
      padding-top: 17px;
      background-color: #3A74A7;
      color: #fff;  
      font-size: 18px;
      cursor: pointer;
      font-family:'Rubik-Medium';
      background: #24395A;
      border-radius: 3px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#24395A' : '#fff'};
        background: ${({active}) => active ? '#fff' : '#24395A'}
      }
    }
  }
  .to-seemore{
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    >button{
      border: none;
      background: none;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;   
      text-transform: lowercase; 
      color: rgba(36, 57, 90, 0.6);
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : 'rgba(36, 57, 90, 0.6)'};
      }
    }
  }
  .hr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  >div{
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      // display: none;
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      // margin-left: 10px;
      margin-left: 30px;
      margin-bottom: 0px;
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 20px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    min-height: 417px; 
    margin-bottom: 20px;
  }



// for mobile view ---------------------------------------------------------------------------------

  .hrr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 20px;
    margin-bottom: 25px;
    // @media (max-width: 500px){
    //   display: none;
    // }
  }

  .display-points{
    // @media (max-width: 500px){
    //   display:  none;
    // }
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#9C9C9C'};
      }
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      // display: none;
      @media (max-width: 1000px){
        height: 20px;
      }
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      // margin-left: 30px;
      margin-bottom: 0px;
      margin-left: 10px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#fff'};
      }
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 0px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    min-height: 360px; 
    margin-bottom: 20px
    background: ${({active}) => active ? 'linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%)' : '#fff'};
  }
`;
export {DropdownTitle, TeamTabContainerV2, AddOnContainerV2, AddOnCardV2, AddOnCardV2Mobile}